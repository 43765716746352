.divprint {
    background-color: white !important;
    height: 100vh !important;
}

.newtable {
    border: 1px solid black !important;
    border-collapse: collapse !important;
  }
  .newtable td {
    border: 1px solid black !important;
    border-collapse: collapse !important;
  }
  
  .pdf {
    background-color:#bfbfbf !important; 
    text-align:center !important;
  }
  
  .text-center {
    text-align: center !important;
  }

  @media print {
    div.divFooter {
        position: fixed;
        bottom: 0;
    }
}