@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;700&display=swap);


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white !important;
}

.newtable {
  border: 1px solid black !important;
  border-collapse: collapse !important;
}
.newtable td {
  border: 1px solid black !important;
  border-collapse: collapse !important;
}

.pdf {
  background-color:#bfbfbf; text-align:center
}

.text_footer {
  font-size: 1.3vw;
}

  div.divFooter {
      position: fixed;
      bottom: 0;
  }

  /* div.phpdebugbar a, div.phpdebugbar-openhandler {
    cursor: pointer;
    display: none;
} */

.cardtohover{
  border-radius: 4px;
  background: #fff;
  /* box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05); */
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
padding: 14px 80px 18px 36px;
cursor: pointer;
}

.cardtohover:hover{
   transform: scale(1.05);
box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}


.cardmain {
	background: linear-gradient(145deg, rgb(243, 240, 236), white);
}
.cardn {
	background: linear-gradient(145deg, blue, white);
}
.cardn:hover {
	background: linear-gradient(145deg, #343174, blue);
}


.cardc{
  border-radius: 4px; 
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
padding: 14px 80px 18px 36px;
cursor: pointer;
}

.cardc:hover{
   transform: scale(1.05);
box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.cardc h3{
font-weight: 600;
}

.cardc img{
position: absolute;
top: 20px;
right: 15px;
max-height: 120px;
}

.card-1{
background-image: url(https://ionicframework.com/img/getting-started/ionic-native-card.png);
    background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}

.card-2{
 background-image: url(https://ionicframework.com/img/getting-started/components-card.png);
    background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}

.card-3{
 background-image: url(https://ionicframework.com/img/getting-started/theming-card.png);
    background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.heightclass {
  height: 100vh !important;
  overflow: auto !important;
}
/* Scrollbar */
::-webkit-scrollbar {
  border: 0;
  height: 10px;
}

/* for scroll bar */
.ScrollBar {
  padding-right: 2rem;
  height: 30rem;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.8s ease-in-out;
}

.btn-primary {
  color: #fff;
  background-color: #000078 !important;
  border-color: #000078 !important;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  height: 320px;
}


::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 1rem;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #000078;
  border-radius: 1rem;
}

.MuiAppBar-colorPrimary {
  background-color: #c2c5c8 !important;
}

.fs-15 {
  font-size: 15px;
}

.fs-12 {
  font-size: 12px;
}


.MuiToolbar-regular {
  max-height: 50px !important;
  min-height: 50px !important;
}

.filterTabs {
  max-height: 44px !important;
  overflow-y: hidden !important;
}

.fullheight-table {
  height: calc(100vh - 53px);
}

.historyTableheight-100 {
  height: calc(100vh - 160px);
}

.groupTableheight-100 {
  height: 450px;
}

.height-100 {
  height: 250px;
}

.heightresponsive-100 {
  height: 350px;
}

.height_ {
  height: calc(100vh - 151px);
}

.height_15 {
  height: calc(100vh - 230px);
}

.heightresponsive_15 {
  height: calc(100vh - 179px);
}

.heightresponsive {
  height: calc(100vh - 100px);
}

.height_14 {
  height: calc(100vh - 190px);
}

.heightresponsive_14 {
  height: calc(100vh - 139px);
}

.height_TabsBar {
  max-height: 0px !important;
}

.width_TabsBar {
  max-height: 220px !important;
}

.negative_margin {
  margin-top: -2.5rem !important;
}

.theme_text {
  color: #000078 !important;
}

.h-35 {
  height: 350px;
}

.row {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}

.btn_theme {
  border: 1px solid #000078 !important;
}

/* Tab buttons */

.MuiTab-textColorInherit {
  min-width: 80px !important;
}

.MuiTab-root {
  padding: 6px 6px !important;
}

/* .jss50 {
  padding: 0px !important;
}

.jss28 {
  width: calc(100% - 74px) !important;
} */

a {
  text-decoration: none !important;
}

.MuiTab-textColorInherit.Mui-selected,
.activeClass {
  box-shadow: #32325d40 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset !important;
  color: #130061 !important;
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.ag-theme-alpine .ag-header-group-cell-label {
  justify-content: center;
}

.container {
  max-width: 100vw !important;
  padding: 0% !important;
}

.logo {
  width: 130px;
  height: 130px;
  display: flex;
  margin: 0px auto 0px auto;
}

.ag-watermark-text,
.ag-watermark,
.ag-opacity-zero {
  /* .ag-theme-alpine [class^=ag-]:before */
  opacity: 0 !important;
}

/* .ag-theme-alpine [class^=ag-], .ag-theme-alpine [class^=ag-]:after, .ag-theme-alpine [class^=ag-]:before, .ag-theme-alpine [class^=ag-]:focus */

/* .dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: blue;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  
  .dropdown-btn:hover
} */

@media (max-width: 770px) {
  .hover:hover .onHover {
    display: none !important;
  }

  .historyTableheight-100 {
    height: calc(100vh - 180px);
  }
}

/* .makeStyles-left-28 {
  width: calc(100% - 77px);
} */

.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #000078;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #000078 !important;
  border-color: #000078 !important;
}

.page-link {
  color: #000078 !important;
}

option.highlighted-option:disabled {
  background-color: lightgray;
  color: gray;
  cursor: not-allowed;
}

.custom-form-control {
  width: 100%;
  max-width: 100%; /* Adjust the value as needed */
}

@media (max-width: 992px) {
  .custom-form-control {
    max-width: 100%; /* Adjust the value as needed */
    max-width: 310px;
  }
}
@media (max-width: 991px) {
  .custom-form-control {
    max-width: 100%; /* Adjust the value as needed */
    max-width: 400px;
  }
}
@media (max-width: 767px) {
  .custom-form-control {
    max-width: 100%; /* Adjust the value as needed */
    max-width: 400px;
  }
}

.custom-form-control2 {
  width: 100%;
  max-width: 500px; /* Adjust the value as needed */
}

@media (max-width: 1030px) {
  .custom-form-control2 {
    max-width: 100%; /* Adjust the value as needed */
    max-width: 300px;
  }
}


@media (max-width: 450px) {
  .custom-form-control2 {
    max-width: 100%; /* Adjust the value as needed */
    max-width: 250px;
  }
}
@media (max-width: 380px) {
  .custom-form-control2 {
    max-width: 100%; /* Adjust the value as needed */
    max-width: 200px;
  }
}

.dropdown-content {
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.themeContainer {
  background-color: #c2c5c8;
}

.innerStyle {
  background-color: #c2c5c8;
  padding: 5px;
}

.ag-full-width-row {
  overflow: scroll !important;
}

/* Loader */

.loader_ {
  /* margin-top: 8px; */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #c2c5c8;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader_::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-bottom: 4px solid #000078;
  border-left: 4px solid transparent;
}

.loader {
  /* margin-top: 8px; */
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #c2c5c8;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-bottom: 4px solid #000078;
  border-left: 4px solid transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.form-control {
  padding: 0.2rem 0.75rem !important;
}

.ag-theme-alpine .ag-rtl .ag-side-bar-left .ag-selected .ag-side-button-button,
.ag-theme-alpine
  .ag-ltr
  .ag-side-bar-right
  .ag-selected
  .ag-side-button-button {
  border-left-color: transparent !important;
  box-shadow: #000078 0px 30px 60px -12px inset,
    #12151d4d 0px 18px 36px -18px inset !important;
  color: white !important;
}

/* .ag-header-cell-label {
height: 100%;
padding: 0 !important;
color: red !important;
} */

/* .animation{
  
} */

.onHover {
  display: none;
}

.hover:hover .onHover {
  display: block;
}

.makeStyles-menuButton-16,
.makeStyles-menuButton-16 {
  margin-right: 0px !important;
}

.fabCustom {
  min-width: 35px !important;
  padding: 0 8px !important;
  height: 35px !important;
  /* background-color: transparent !important; */
  /* box-shadow: none !important; */
}

.MuiTabs-flexContainerVertical {
  width: 200px;
}

.responsiveTableFonts {
  font-size: 14px !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, Helvetica Neue, sans-serif !important;
}

.ag-theme-alpine .ag-group-contracted,
.ag-theme-alpine .ag-group-expanded,
.ag-theme-alpine .ag-row-drag,
.ag-theme-alpine .ag-selection-checkbox {
  color: #000078 !important;
}

.items_middle {
  align-items: center;
  display: flex;
  min-height: 100vh;
}

.theme_bg {
  background: #000078;
}

.text_grey {
  color: #e8e8e8;
}

.bg_grey {
  background-color: #e8e8e8;
}

.border_radius {
  border-radius: 10px;
}

.details_card {
  /* background: #E8E8E8; */
  color: #000078;
  /* border-radius: 10px; */
  border: 1px solid #000078;
  padding: 10px;
  text-align: center;
  justify-content: center;
}

.card_heading_main {
  background: #e8e8e8;
  color: #000078;
  padding: 10px;
  text-align: center;
  justify-content: center;
  height: calc(100vh - 102px);
}

.historyCards {
  border-radius: 10px;
  border: 1px solid #000078;
  padding: 10px;
  text-align: center;
  justify-content: center;
  height: 100%;
  overflow: scroll;
}

.height_home {
  height: calc(100vh - 166px);
}

@media (max-width: 600px) {
  .historyCards {
    border-radius: 0px;
    border: none;
    padding: 0px;
    height: 100%;
    overflow: scroll;
  }

  .height_home {
    height: calc(100vh - 223px);
  }
}

.marginRight {
  margin-right: 8vw !important;
}

.ag-theme-alpine .ag-status-bar {
  max-height: 40px;
}

.rounded_Button .MuiButtonBase-root {
  border-radius: 24px !important;
}

.username {
  color: #204162 !important;
}

.topMargin {
  top: 35px !important;
}

.text {
  color:"#000078";
}

.statusBarCustom {
  /* position: absolute;
  bottom: 0; */
  background: white;
  width: 100%;
  overflow: scroll;
}

.heading_card {
  height: 80vh;
  width: 55vw;
  overflow: scroll;
  padding: 30px;
  text-align: center;
  vertical-align: middle;
  background: #c2c5c8;
  /* background: #000078; */
  color: #000078;
  font-family: sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: default;
}

.heading_container {
  position: relative;
  margin-top: 130px;
  height: 70vh;
  width: 100%;
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: middle;
}

/* 
.Data_card {
  height: 70px;
  width: 200px;
  padding-top: 25px;
  text-align: center;
  vertical-align: middle;
  background: #000078;
  color: #FFF;
  font-family: sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 10px;
  box-sizing: border-box;
}

.Data_container {
  position: relative;
  height: 33vh;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: middle;
} */

.searchCard {
  background: #fbfbfb;
  border-radius: 10px;
  border: 1px solid #000078;
  width: 100%;
}

.card_expand {
  color: #e8e8e8 !important;
  background: #000078 !important;
  height: 90px !important;
}

.card_expanded {
  min-height: 300px !important;
}

.card_expandable-sm {
  padding: 8px !important;
  border-radius: 15px;
  height: 60px !important;
}

.card_expand-sm {
  color: #e8e8e8 !important;
  background: #000078 !important;
  height: 60px !important;
}

.card_expandable {
  padding: 8px !important;
  border-radius: 15px;
}

.table_style {
  height: 300px;
  overflow: scroll;
}

.overflow_scroll {
  overflow: scroll !important;
}

.blink_text {

  animation: 1s blinker linear infinite;
  -webkit-animation: 1s blinker linear infinite;
  -moz-animation: 1s blinker linear infinite;

  color: red;
}

.sadFace {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.face {
  width: 150px;
  height: 150px;
  border: 9px solid #000078;
  border-radius: 50%;
  animation: face 7s ease;
  animation-delay: 2s;
  position: relative;
  z-index: 9999;
}

.mouth {
  height: 10px;
  width: 35px;
  margin: 0 auto;
  margin-top: 15px;
  background-color: #000078;
  animation: mouth 7s ease;
  animation-delay: 2s;
}

.tear {
  position: absolute;
  background-color: #3498db;
  height: 0px;
  width: 45px;
  top: 62px;
  left: 29px;
  border-bottom-left-radius: 50%;
  animation: tear 7s ease;
  animation-delay: 2s;
}

.tear2 {
  position: absolute;
  background-color: #3498db;
  height: 0px;
  width: 45px;
  top: 62px;
  left: 98px;
  border-bottom-right-radius: 50%;
  animation: tear 7s ease;
  animation-delay: 2s;
}

.eye {
  height: 10px;
  width: 45px;
  background-color: #000078;
  display: inline-block;
  margin-left: 20px;
  margin-top: 20px;
  animation: cry 7s ease;
  animation-delay: 2s;
}

.eyebrow {
  width: 30px;
  font-size: 40px;
  font-weight: bold;
  color: #000078;
  display: inline-block;
  margin-left: 27px;
  margin-top: 10px;
  opacity: 0;
  animation: eyebrow 7s ease;
  animation-delay: 2s;
}

.eyebrow:nth-child(1) {
  transform: rotate(-20deg);
}

.eyebrow:nth-child(2) {
  transform: rotate(20deg);
}

@keyframes mouth {
  10% {
    height: 30px;
    width: 25px;
    border: 6px solid black;
    border-radius: 50%;
    margin-top: -8px;
  }
  50% {
    height: 30px;
    width: 25px;
    border: 6px solid black;
    border-radius: 50%;
    margin-top: -8px;
  }
  60% {
    height: 10px;
    width: 35px;
    border-radius: 0%;
    border: none;
    margin-top: 15px;
  }
}

@keyframes cry {
  10% {
    transform: translateY(-34px);
    background-color: black;
  }
  50% {
    transform: translateY(-34px);
    background-color: black;
  }
  60% {
    background-color: #000078;
    transform: translateY(0px);
  }
}

@keyframes eyebrow {
  10% {
    opacity: 1;
    color: black;
  }
  50% {
    opacity: 1;
    color: black;
  }
  60% {
    opacity: 0;
  }
}

@keyframes face {
  10% {
    border-color: black;
  }
  50% {
    border-color: black;
  }
  60% {
    border-color: #000078;
  }
}

@keyframes tear {
  10% {
    height: 96px;
  }
  50% {
    height: 96px;
  }
  52% {
    height: 0px;
  }
}
#result {
  border: 1px dotted #ccc;
  padding: 3px;
}
#result ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
#result ul li {
  padding: 5px 0;
}
#result ul li:hover {
  background: #eee;
}


.hourglass{
  display:block;
  background: #fbfbfb !important;;
  margin:3em auto;
  width:2em;
  height:4em;
  animation:hourglass 1s linear infinite;
}

.outer{
  fill:#000078;
}

.middle{
  fill: #fbfbfb;
}

@keyframes hourglass{
  0%{
      transform:rotate(0deg);
      box-shadow:
          inset #000078 0 -0em 0 0,
          inset #fbfbfb 0 -2em 0 0,
          inset #000078 0 -4em 0 0;
  }
  80%{
      transform:rotate(0deg);
      box-shadow:
          inset #000078 0 -2em 0 0,
          inset #fbfbfb 0 -2em 0 0,
          inset #000078 0 -2em 0 0;
  }
  100%{
      transform:rotate(180deg);
      box-shadow:
          inset #000078 0 -2em 0 0,
          inset #fbfbfb 0 -2em 0 0,
          inset #000078 0 -2em 0 0;
  }




}

.comment {
  /* width: 100px;
  height: 100px; */
  background-color: yellow;
  animation: color-animation 0.5s infinite alternate;
}

@keyframes color-animation {
  0% {
    background-color: yellow;
  }
  100% {
    background-color: orange;
  }
}
.comment2 {
  /* width: 100px;
  height: 100px; */
  background-color: #000078;
  animation: color-animation2 0.5s infinite alternate;
  color: white;
}

@keyframes color-animation2 {
  0% {
    background-color: #000078;
  }
  100% {
    background-color: blue;
  }
}



*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #efefef !important;
}

.logo_size {
  width: 120px;
}

.shadow_1 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.shadow_2 {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

form {
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 50px 35px;
}

@media (max-width: 767px) {
  .min_height {
    align-items: center;
    display: flex;
    min-height: 100vh;
  }

}

form * {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}

.input_ {
  display: block;
  height: 50px;
  width: 100%;
  background-color: #c2c5c8 !important;
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
}

.button_ {
  margin-top: 30px;
  width: 100%;
  background-color: #000078;
  color: #c2c5c8;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.avatar {
  border-radius: 50%;
  width: 35px;
  margin-right: 10px;
}

@media (max-width: 770px) {

  .topMargin {
    top: 60px !important;
  }
}
.App {
    font-family: sans-serif;
  }
  
  .dropdown {
    position: relative;
    color: #333;
    cursor: default;
  }
  
  .dropdown .arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: " ";
    display: block;
    height: 0;
    margin-top: 0.3rem;
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
  }
  
  .dropdown .arrow.open {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }
  
  .dropdown .selected-value input {
    line-height: 1.5;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
  }
  
  .dropdown .options {
    display: none;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }
  
  .dropdown .options.open {
    display: block;
  }
  
  .dropdown .option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }
  
  .dropdown .option.selected,
  .dropdown .option:hover {
    background-color: #f2f9fc;
    color: #333;
  }
h3 {
  color: #000078 !important;
}

.brdr_btm {
  border-bottom: none !important;
}
@media print {
  .text {
    font-size: 1.5vw !important;
  }
  .link {
    width: 0.7em !important;
    height: 0.3em !important;
  }

  .forminput {
    height: 20px !important;
  }

  .printNone {
    display: unset !important;
  }

  .onPrintNone {
    display: none !important;
  }

  .bodyPadding {
    padding: 25px !important;
  }
}

.bodyPadding {
  padding: 25px !important;
}

.onPrintNone {
  display: unset;
}

.printNone {
  display: none;
}

.bg-theme {
  background-color: #e8e8e8 !important;
}

.App-header {
  /* background-color: #c2c5c8 ; */
  background-color: #e8e8e8;
  min-height: 100vh;
  color: black;
  padding: 20px;
}

.App-link {
  color: #61dafb;
}

form label {
  font-size: 14px;
  font-weight: 600;
}

.table-light {
  background-color: #c2c5c8 !important;
}

.borderr {
  border: 2px solid #000078 !important;
  border-radius: 10px;
}

.accordion-button {
  background: none !important;
  color: black !important;
}

.form_input,
.hover_btn {
  max-height: 30px !important;
  font-size: small !important;
  padding-top: 2px !important;
  transition: transform 0.2s;
}

.hover_btn {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.hover_btn:hover {
  transform: scale(1.04);
  opacity: 0.7 !important;
  filter: invert(0.7);
}

th,
td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.css-i4bv87-MuiSvgIcon-root {
  height: 25px !important;
}
h5 {
  color: #000078 !important;
}

h4 {
  color: #000078 !important;
}

.divclass {
  z-index: 11;
}

.container{max-width:1170px; margin:auto;}
img{ max-width:100%;}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%; border-right:1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac{ margin: 20px 0 0;}


.recent_heading {float: left; width:40%;}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.headind_srch{ padding:10px 29px 10px 20px; overflow:hidden; border-bottom:1px solid #c4c4c4;}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon { margin: 0 0 0 -27px;}

.chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
.chat_ib h5 span{ font-size:13px; float:right;}
.chat_ib p{ font-size:14px; color:#989898; margin:auto}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people{ overflow:hidden; clear:both;}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat { height: 550px; overflow-y: scroll;}

.active_chat{ background:#ebebeb;}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
 }
 .received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg { width: 57%;}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 100%;
  height: 250px;
  overflow: scroll;
}

 .sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0; color:#fff;
  padding: 5px 10px 5px 12px;
  width:100%;
}
.outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {border-top: 1px solid #c4c4c4;position: relative;}
.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  right: 0;
  top: 11px;
  width: 33px;
}
.messaging { padding: 0 0 50px 0;}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
  font-family: 'Poppins', sans-serif;
  align-items: center;
  justify-content: center;
  background-color: #ADE5F9;
  min-height: 100vh;
} */
img {
  max-width: 100%;
  display: block;
}
ul {
  list-style: none;
}

/* Utilities */
.cardexternal::after,
.cardexternal img {
  border-radius: 50%;
}
/* body, */
.cardexternal,
.stats {
  display: flex;
}

.cardexternal {
  padding: 2.5rem 2rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, .5);
  max-width: 850px;
  box-shadow: 0 0 30px rgba(0, 0, 0, .15);
  margin: 1rem;
  position: relative;
  transform-style: preserve-3d;
  overflow: hidden;
}
.cardexternal::before,
.cardexternal::after {
  content: '';
  position: absolute;
  z-index: -1;
}
.cardexternal::before {
  width: 100%;
  height: 100%;
  border: 1px solid #FFF;
  border-radius: 10px;
  top: -.7rem;
  left: -.7rem;
}
.cardexternal::after {
  height: 12rem;
  width: 12rem;
  background-color: #000078;
  top: -8rem;
  right: -8rem;
  box-shadow: 2rem 6rem 0 -3rem #FFF
}

.cardexternal img {
  width: 8rem;
  min-width: 80px;
  box-shadow: 0 0 0 5px #FFF;
}

.infos {
  margin-left: 1.5rem;
}

.name {
  margin-bottom: 1rem;
}
.name h2 {
  font-size: 1.3rem;
}
.name h4 {
  font-size: .8rem;
  color: #333
}

.text {
  font-size: .9rem;
  margin-bottom: 1rem;
}

.stats {
  margin-bottom: 1rem;
}
.stats li {
  min-width: 5rem;
}
.stats li h3 {
  font-size: .99rem;
}
.stats li h4 {
  font-size: .75rem;
}

.links button {
  font-family: 'Poppins', sans-serif;
  min-width: 120px;
  padding: .5rem;
  border: 1px solid #222;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: all .25s linear;
}
.links .follow,
.links .view:hover {
  background-color: #222;
  color: #FFF;
}
.links .view,
.links .follow:hover{
  background-color: transparent;
  color: #222;
}

@media screen and (max-width: 450px) {
  .cardexternal {
    display: block;
  }
  .infos {
    margin-left: 0;
    margin-top: 1.5rem;
  }
  .links button {
    min-width: 100px;
  }
}

.divprint {
    background-color: white !important;
    height: 100vh !important;
}

.newtable {
    border: 1px solid black !important;
    border-collapse: collapse !important;
  }
  .newtable td {
    border: 1px solid black !important;
    border-collapse: collapse !important;
  }
  
  .pdf {
    background-color:#bfbfbf !important; 
    text-align:center !important;
  }
  
  .text-center {
    text-align: center !important;
  }

  @media print {
    div.divFooter {
        position: fixed;
        bottom: 0;
    }
}
